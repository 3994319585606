// config to enable all features in the jodit editor
    export const config = {
        readonly: true, // all options from https://xdsoft.net/jodit/doc/,
        uploader: {
            url: 'https://xdsoft.net/jodit/finder/?action=fileUpload'
        },
        filebrowser: {
            ajax: {
                url: 'https://xdsoft.net/jodit/finder/'
            },
            height: 580,
        },
        language: 'es',
        controls: {
            lineHeight: {
                list: ([0.5, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 2, 3, 3.5])

            }
        }
    }
    export const configForUniqueResponse = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        height: 580,
        uploader: {
            url: 'https://xdsoft.net/jodit/finder/?action=fileUpload'
        },
        filebrowser: {
            ajax: {
                url: 'https://xdsoft.net/jodit/finder/'
            },
            height: 580,
        },
        language: 'es',
        controls: {
            lineHeight: {
                list: ([0.5, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 2, 3, 3.5])

            }
        }
    }